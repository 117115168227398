import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Row,
  Col,
  Tag,
  Badge,
  Spin,
  Alert,
  Avatar,
  Typography,
  DatePicker,
  Select
} from "antd";
import {
  TeamOutlined,
  BuildOutlined,
  HomeOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  PhoneOutlined,
  MailOutlined
} from "@ant-design/icons";
import companyService from "../../../services/company/company.service";
import { Company, CompanyStatus } from "../../../types/company.types";
import moment from "moment";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";
import "./styles.css";
// Parça bileşenler
import StatCards from "./components/StatCards";
import CompanyStatusChart from "./components/CompanyStatusChart";
import RecentCompanies from "./components/RecentCompanies";
import QuickAccessCards from "./components/QuickAccessCards";
import GrowthChart from "./components/GrowthChart";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface DashboardStats {
  totalCompanies: number;
  activeCompanies: number;
  inactiveCompanies: number;
  suspendedCompanies: number;
  totalEmployees: number;
  totalBranches: number;
  totalDepartments: number;
}

const SuperAdminDashboard: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [recentCompanies, setRecentCompanies] = useState<Company[]>([]);
  const [stats, setStats] = useState<DashboardStats>({
    totalCompanies: 0,
    activeCompanies: 0,
    inactiveCompanies: 0,
    suspendedCompanies: 0,
    totalEmployees: 0,
    totalBranches: 0,
    totalDepartments: 0
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<string>('month');
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Şirketleri getir
      const companiesData = await companyService.getAllCompanies();
      setCompanies(companiesData);

      // Son 5 şirketi al
      const sortedCompanies = [...companiesData].sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setRecentCompanies(sortedCompanies.slice(0, 5));

      // İstatistikleri hesapla
      calculateStats(companiesData);
    } catch (error: any) {
      handleApiError(error, t);
      setError("Veriler yüklenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (companies: Company[]) => {
    const newStats = {
      totalCompanies: companies.length,
      activeCompanies: companies.filter(c => c.status === CompanyStatus.ACTIVE).length,
      inactiveCompanies: companies.filter(c => c.status === CompanyStatus.INACTIVE).length,
      suspendedCompanies: companies.filter(c => c.status === CompanyStatus.SUSPENDED).length,
      totalEmployees: 0,
      totalBranches: 0,
      totalDepartments: 0
    };

    // Toplam çalışan, şube ve departman sayılarını hesapla
    companies.forEach(company => {
      newStats.totalEmployees += company.users?.length || 0;
      newStats.totalBranches += company.branches?.length || 0;
      newStats.totalDepartments += company.departments?.length || 0;
    });

    setStats(newStats);
  };

  // Tarih aralığı değişimi için handler
  const handleTimeRangeChange = (value: string) => {
    setTimeRange(value);
    // Gerçek uygulamada burada seçilen aralığa göre veri çekilebilir
  };

  // Şirket verilerinden son 6 aylık büyüme grafiği verisi oluştur
  const getGrowthChartData = () => {
    if (!companies || companies.length === 0) {
      return [];
    }

    // Son 6 ay için boş bir veri yapısı oluştur
    const lastSixMonths: { [key: string]: { name: string, Aktif: number, Pasif: number, Total: number } } = {};
    
    // Son 6 ayı oluştur (şu anki ay dahil)
    for (let i = 5; i >= 0; i--) {
      const date = moment().subtract(i, 'months');
      const monthKey = date.format('YYYY-MM');
      const monthName = date.format('MMM'); // Kısa ay adı
      
      lastSixMonths[monthKey] = {
        name: monthName,
        Aktif: 0,
        Pasif: 0,
        Total: 0
      };
    }
    
    // Her şirket için, oluşturulma ayına göre sayıları arttır
    companies.forEach(company => {
      const createdAt = moment(company.createdAt);
      const monthKey = createdAt.format('YYYY-MM');
      
      // Eğer son 6 ay içindeyse
      if (lastSixMonths[monthKey]) {
        lastSixMonths[monthKey].Total += 1;
        
        if (company.status === CompanyStatus.ACTIVE) {
          lastSixMonths[monthKey].Aktif += 1;
        } else {
          lastSixMonths[monthKey].Pasif += 1;
        }
      }
    });
    
    // Object değerlerini diziye çevir ve döndür
    return Object.values(lastSixMonths);
  };

  // RecentCompanies bileşenine veri göndermek için dönüştürme işlevi
  const mapCompaniesToCompanyData = (companies: Company[]): any[] => {
    return companies.map(company => ({
      id: company.id,
      name: company.name,
      email: company.email || '',  // Undefined olabilecek alanlara varsayılan değer ataması
      phone: company.phone || '',
      taxNumber: company.taxNumber || '',
      status: company.status,
      users: company.users || [],
      branches: company.branches || [],
      departments: company.departments || [],
      createdAt: company.createdAt
    }));
  };

  if (loading) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Spin size="large" />
        <p className="mt-3">Veriler yükleniyor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Alert
          message="Hata"
          description={error}
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill">
          {/* Üst Başlık ve Filtre Bölümü */}
          <div className="dashboard-header">
            <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb">
              <div className="my-auto mb-2">
                <Title level={3} className="mb-1">Süper Admin Paneli</Title>
                <Text type="secondary">
                  Hoş geldiniz! İşte şirketlerinizin genel durumu.
                </Text>
              </div>
            </div>
          </div>

          <div className="dashboard-container">
            {/* İstatistik Kartları */}
            <StatCards stats={stats} />
            
            {/* Grafik Bölümleri */}
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <CompanyStatusChart 
                  data={{
                    activeCompanies: stats.activeCompanies,
                    inactiveCompanies: stats.inactiveCompanies,
                    suspendedCompanies: stats.suspendedCompanies,
                    totalCompanies: stats.totalCompanies
                  }} 
                />
              </Col>
              <Col xs={24} lg={12}>
                <GrowthChart data={getGrowthChartData()} />
              </Col>
            </Row>
            
            {/* Hızlı Erişim Kartları */}
            <QuickAccessCards companyCount={stats.totalCompanies} />
            
            {/* Son Eklenen Şirketler */}
            <RecentCompanies 
              companies={mapCompaniesToCompanyData(recentCompanies)} 
              loading={false} 
            />
          </div>
        </div>
        
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
          <FooterSection />
        </div>
      </div>
    </>
  );
};

export default SuperAdminDashboard; 