import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSocket } from "../../../../hooks/useSocket";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import dailyRecordService from "../../../../services/dailyRecord/dailyRecord.service";
import { CompanyActivityDto } from "../../../../types/attendanceDto.types";
import { message, Modal, Button, Avatar } from "antd";
import { useLeaves } from "../../../../hooks/useLeaves";
import { LeaveStatus } from "../../../../types/leaves.types";
import { CheckOutlined, CloseOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { getFileUrl } from "../../../../utils/file.utils";
import { useTranslation } from "react-i18next";
import { handleApiError } from "../../../../utils/errorHandler";

const LeaveStatusComponent: React.FC = () => {
  const {t} = useTranslation();
  const [activities, setActivities] = useState<CompanyActivityDto[]>([]);
  const { connected, on } = useSocket();
  const {companyId} = useSelector((state: RootState) => state.auth);
  const [activeLeaveCount, setActiveLeaveCount] = useState<number>(0);
  const { updateLeaveStatus } = useLeaves();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingId, setLoadingId] = useState<number | null>(null);
  const [selectedLeave, setSelectedLeave] = useState<CompanyActivityDto | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (companyId) {
      const fetchCompanyActivity = async () => {
        try {
          const response = await dailyRecordService.getCompanyActivity(companyId);
          
          const filteredActivities = response.filter(
            (activity: CompanyActivityDto) => activity.type === "leave_request"
          );
          
          const sortedActivities = filteredActivities.sort(
            (a: CompanyActivityDto, b: CompanyActivityDto) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          
          setActivities(sortedActivities);
          setActiveLeaveCount(sortedActivities.length);
        } catch (error: any) {
          handleApiError(error,t);
        }
      };
      
      fetchCompanyActivity();
    }
  }, [companyId]);

  useEffect(() => {
    if (connected) {
      const handleNewActivity = (data: CompanyActivityDto) => {
        if (data.type === "leave_request") {
          setActivities(prev => {
            if (prev.some(a => a.id === data.id)) {
              return prev;
            }
            
            const newActivities = [data, ...prev];
            setActiveLeaveCount(newActivities.length);
            return newActivities;
          });
        }
      };

      on("newActivity", handleNewActivity);
    }
  }, [connected, on]);

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
  };

  const getStatusIcon = (status: string | number) => {
    const statusStr = String(status);
    switch (statusStr) {
      case 'approved':
        return <CheckOutlined style={{ color: '#52c41a' }} />;
      case 'rejected':
        return <CloseOutlined style={{ color: '#ff4d4f' }} />;
      default:
        return <i className="ti ti-edit" style={{ color: '#faad14', fontSize: '22px' }} />;
    }
  };

  const handleStatusChange = async (leaveId: number, dto: any) => {
    try {
      setLoadingId(leaveId);
      setIsLoading(true);
      await updateLeaveStatus(leaveId, dto);
      message.success("İzin durumu başarıyla güncellendi");
      
      if (companyId) {
        const response = await dailyRecordService.getCompanyActivity(companyId);
        const filteredActivities = response.filter(
          (activity: CompanyActivityDto) => activity.type === "leave_request"
        );
        const sortedActivities = filteredActivities.sort(
          (a: CompanyActivityDto, b: CompanyActivityDto) => 
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setActivities(sortedActivities);
      }
      setIsModalVisible(false);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setIsLoading(false);
      setLoadingId(null);
    }
  };

  const showModal = (activity: CompanyActivityDto) => {
    setSelectedLeave(activity);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setSelectedLeave(null);
    setIsModalVisible(false);
  };

  return (
    <div className="col-xxl-4 col-xl-6 d-flex">
      <div className="card flex-fill">
        <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
          <h5 className="mb-2">{t("dashboard.leaveRequests.title")}</h5>
        </div>
        <div className="card-body">
          <div style={{ maxHeight: '350px', overflowY: 'auto', marginBottom: '10px' }}>
            {activities.length > 0 ? (
              activities.map((activity) => (
                <div 
                  key={activity.id} 
                  className="d-flex align-items-center justify-content-between mb-3 p-2 border border-dashed br-5"
                  onClick={() => showModal(activity)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="d-flex align-items-center">
                  <Link to="#" className="avatar flex-shrink-0">
                    {activity?.user?.profilePicture ? (
                      <Avatar src={getFileUrl(activity?.user?.profilePicture)} size="large" />
                    ) : (
                      <Avatar icon={<UserOutlined />} size="default" />
                    )}
                  </Link>
                    <div className="ms-2">
                      <h6 className="fs-14 fw-medium text-truncate">
                        {activity.user.firstName + " " + activity.user.lastName}
                      </h6>
                      <p className="fs-13">
                        {activity.description}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    {loadingId === activity.extraData?.leaveId ? (
                      <LoadingOutlined style={{ fontSize: 16 }} />
                    ) : (
                      getStatusIcon(activity.extraData?.status || 'pending')
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-3">
                <p className="mb-0">{t("dashboard.leaveRequests.noLeaveToday")}</p>
              </div>
            )}
          </div>
          
          <Link
            to="/leaves"
            className="btn btn-light btn-md w-100 mt-2"
          >
            {t("dashboard.leaveRequests.allRecords")}
          </Link>
        </div>
      </div>

      {/* İzin Detay Modal */}
      <Modal
        title={
          <div className="d-flex align-items-center border-bottom pb-3">
            <div className="d-flex align-items-center flex-grow-1">
              <span className={`badge ${selectedLeave?.extraData?.status === 'pending' ? 'bg-warning' : selectedLeave?.extraData?.status === 'approved' ? 'bg-success' : 'bg-danger'} me-3`} style={{ fontSize: '1rem', padding: '8px 12px' }}>
                #{selectedLeave?.extraData?.leaveId}
              </span>
              <h5 className="mb-0">{selectedLeave?.description}</h5>
            </div>
          </div>
        }
        open={isModalVisible}
        onCancel={hideModal}
        footer={[
          <Button key="close" onClick={hideModal}>
            {t("dashboard.leaveRequests.close")}
          </Button>,
          selectedLeave?.extraData?.status === 'pending' && (
            <>
              <Button
                key="reject"
                danger
                loading={isLoading}
                onClick={() => handleStatusChange(Number(selectedLeave.extraData?.leaveId), { status: 'rejected' })}
              >
                {t("dashboard.leaveRequests.reject")}
              </Button>
              <Button
                key="approve"
                type="primary"
                loading={isLoading}
                onClick={() => handleStatusChange(Number(selectedLeave.extraData?.leaveId), { status: 'approved' })}
              >
                {t("dashboard.leaveRequests.approve")}
              </Button>
            </>
          )
        ]}
        width={700}
      >
        <div className="leave-details-modal">
          <div className="user-info mb-4 p-3 bg-light rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-wrapper me-3">
                <ImageWithBasePath
                  src={selectedLeave?.user.avatar || "assets/img/profiles/avatar-09.jpg"}
                  alt="User"
                  className="avatar avatar-md rounded-circle border border-2 shadow-sm"
                />
              </div>
              <div className="user-details flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="mb-1">{selectedLeave?.user.firstName} {selectedLeave?.user.lastName}</h6>
                    <p className="text-muted mb-0 small">{selectedLeave?.user.email}</p>
                  </div>
                  <div className="text-end">
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-calendar me-1"></i> 
                      {selectedLeave && new Date(selectedLeave.createdAt).toLocaleDateString('tr-TR', { 
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-clock me-1"></i>
                      {selectedLeave && new Date(selectedLeave.createdAt).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="leave-content">
            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-file-description me-2"></i>
                {t("dashboard.leaveRequests.description")}
              </h6>
              <div className="description p-3 border rounded bg-white">
                <p className="mb-0" style={{ lineHeight: '1.6' }}>
                  {selectedLeave?.description || t("dashboard.leaveRequests.noDescription")}
                </p>
              </div>
            </div>

            {selectedLeave?.extraData?.documentUrl && (
              <div className="section mb-4">
                <h6 className="text-primary mb-3">
                  <i className="ti ti-file me-2"></i>
                  {t("dashboard.leaveRequests.document")}
                </h6>
                <div className="document p-3 border rounded bg-white">
                  <a 
                    href={getFileUrl(selectedLeave?.extraData?.documentUrl as string)} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-light btn-sm"
                  >
                    <i className="ti ti-file-download me-2"></i>
                    {t("dashboard.leaveRequests.viewDocument")}
                  </a>
                </div>
              </div>
            )}

            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-info-circle me-2"></i>
                {t("dashboard.leaveRequests.statusInfo")}
              </h6>
              <div className="status-info p-3 border rounded bg-white">
                <div className="d-flex align-items-center">
                  <span 
                    className={`status-indicator ${selectedLeave?.extraData?.status === 'pending' ? 'bg-warning' : selectedLeave?.extraData?.status === 'approved' ? 'bg-success' : 'bg-danger'} me-2`}
                    style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block' }}
                  ></span>
                  <span className="status-text">
                    {selectedLeave?.extraData?.status === 'pending' 
                      ? t("dashboard.leaveRequests.pending")
                      : selectedLeave?.extraData?.status === 'approved'
                      ? t("dashboard.leaveRequests.approved")
                      : t("dashboard.leaveRequests.rejected")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LeaveStatusComponent; 