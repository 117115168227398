import { Navigate } from "react-router-dom";
import { RolesEnum } from "../types/route.types";
import { PermissionType } from "../types/user.types";
import { all_routes } from "./all_routes";
interface ProtectedRouteProps {
  element: React.ReactNode;
  allowedRoles: PermissionType[]; // Dizi olarak değişti
  userRole?: PermissionType[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  allowedRoles,
  userRole
}) => {
  console.log('Kullanıcı yetkileri:', userRole);
  console.log('Gereken yetkiler:', allowedRoles);
  
  // Kullanıcının hiç yetkisi yoksa ana sayfaya yönlendir
  if (!userRole || userRole.length === 0) {
    return <Navigate to="/" replace />;
  }

  // Yetki kontrolü - kullanıcının en az bir gerekli yetkiye sahip olması gerekir
  const hasPermission = allowedRoles.some(role => userRole.includes(role));
  
  if (!hasPermission) {
    // USER rolü için control_panel_user yetkisi varsa, user dashboard'a yönlendir
    if (userRole.includes(PermissionType.CONTROL_PANEL_USER)) {
      return <Navigate to={all_routes.employeeDashboard} replace />;
    }
    // ADMIN rolü için control_panel_admin yetkisi varsa, admin dashboard'a yönlendir
    else if (userRole.includes(PermissionType.CONTROL_PANEL_ADMIN)) {
      return <Navigate to={all_routes.adminDashboard} replace />;
    }
    // Diğer durumlar için ana sayfaya yönlendir
    return <Navigate to="/" replace />;
  }

  return <>{element}</>;
};

export default ProtectedRoute;