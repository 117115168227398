import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../../router/all_routes";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import authService from "../../../../services/auth/auth.service";
import { message } from "antd";
import { handleApiError } from "../../../../utils/errorHandler";

const ForgotPassword2 = () => {
  const routes = all_routes;
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      message.error("Lütfen email adresinizi giriniz");
      return;
    }

    try {
      setLoading(true);
      await authService.sendOtp(email);
      message.success("Şifre sıfırlama kodu email adresinize gönderildi");
      navigation(routes.twoStepVerification2, { state: { email } });
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-5">
          <div className="d-lg-block align-items-center justify-content-center d-none flex-wrap vh-100">
            <div>
                <ImageWithBasePath src="assets/img/bg/authentication-bg-04.svg" alt="Img" />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-7 mx-auto vh-100">
                <form className="vh-100" onSubmit={handleSubmit}>
                <div className="vh-100 d-flex flex-column justify-content-between" style={{paddingTop: "35%", paddingRight: "1.5rem",paddingLeft: "1.5rem",paddingBottom: "1.5rem"}}>
                  
                  <div className="flex-grow-0">
                    <div className="text-center mb-4">
                    <div className="mx-auto text-center mb-4" style={{width: "60%"}}>
                    <ImageWithBasePath src="assets/img/dinox-light.png" alt="Logo"/>
                  </div>
                        <h2 className="mb-2">Şifremi Unuttum</h2>
                        <p className="mb-0">
                          Şifrenizi sıfırlamak için email adresinizi giriniz.
                          Size şifre sıfırlama kodu göndereceğiz.
                        </p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Email Adresi</label>
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control border-end-0"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <span className="input-group-text border-start-0">
                            <i className="ti ti-mail" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <button 
                          type="submit" 
                          className="btn btn-primary w-100"
                          disabled={loading}
                        >
                          {loading ? "Gönderiliyor..." : "Gönder"}
                        </button>
                      </div>
                      <div className="text-center">
                        <h6 className="fw-normal text-dark mb-0">
                          Giriş sayfasına
                          <Link to={all_routes.login2} className="hover-a">
                            {" "}dön
                          </Link>
                        </h6>
                      </div>
                    </div>
                    <div className="mt-5 pb-4 text-center">
                      <p className="mb-0 text-gray-9">Copyright © Dinox - {new Date().getFullYear()}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword2;
