import React, { useState } from 'react';
import { Card, Typography, Select } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import 'moment/locale/tr';

const { Title, Text } = Typography;
const { Option } = Select;

// Şirket büyüme veri modeli
interface GrowthData {
  name: string;
  Aktif: number;
  Pasif: number;
  Total: number;
}

interface GrowthChartProps {
  data: GrowthData[];
}

const GrowthChart: React.FC<GrowthChartProps> = ({ data }) => {
  const [timeRange, setTimeRange] = useState<string>('month');
  moment.locale('tr'); // Türkçe tarih formatları için

  // Zaman aralığı değişim olayı
  const handleTimeRangeChange = (value: string) => {
    setTimeRange(value);
    // Gerçek uygulamada burada seçilen aralığa göre veri çekme işlemi olabilir
  };

  return (
    <Card 
      title={<Title level={5}><BarChartOutlined /> Şirket Büyüme Grafiği</Title>}
      className="dashboard-chart-card mb-4"
      bordered={false}
    >
      <div className="d-flex justify-content-end mb-3">
        <Select 
          defaultValue="month" 
          style={{ width: 120 }} 
          onChange={handleTimeRangeChange}
        >
          <Option value="week">Haftalık</Option>
          <Option value="month">Aylık</Option>
          <Option value="quarter">Çeyrek</Option>
          <Option value="year">Yıllık</Option>
        </Select>
      </div>
      <div className="chart-container" style={{ height: '250px' }}>
        {data.length === 0 ? (
          <div className="empty-container">
            <Text type="secondary">Henüz yeterli veri bulunmamaktadır</Text>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={(value) => [`${value} şirket`, '']} />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="Total" 
                name="Toplam Şirket" 
                stroke="#8884d8" 
                activeDot={{ r: 8 }} 
                strokeWidth={2} 
              />
              <Line 
                type="monotone" 
                dataKey="Aktif" 
                name="Aktif Şirket" 
                stroke="#52c41a" 
              />
              <Line 
                type="monotone" 
                dataKey="Pasif" 
                name="Pasif Şirket" 
                stroke="#ff4d4f" 
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
};

export default GrowthChart; 