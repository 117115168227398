import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSocket } from "../../../../hooks/useSocket";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import dailyRecordService from "../../../../services/dailyRecord/dailyRecord.service";
import { CompanyActivityDto } from "../../../../types/attendanceDto.types";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { getFileUrl } from "../../../../utils/file.utils";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../../router/all_routes";
import { handleApiError } from "../../../../utils/errorHandler";

const ExcuseStatus: React.FC = () => {
  const { t } = useTranslation();
  const [activities, setActivities] = useState<CompanyActivityDto[]>([]);
  const { connected, on } = useSocket();
  const { companyId } = useSelector((state: RootState) => state.auth);
  const routes = all_routes;

  // API'den mazeret aktivitelerini al
  useEffect(() => {
    if (companyId) {
      const fetchCompanyActivity = async () => {
        try {
          const response = await dailyRecordService.getCompanyActivity(companyId);
          
          // Sadece mazeret aktivitelerini filtrele
          const filteredActivities = response.filter(
            (activity: CompanyActivityDto) => activity.type === "excuse"
          );
          
          // Aktiviteleri en yeniden en eskiye doğru sırala
          const sortedActivities = filteredActivities.sort(
            (a: CompanyActivityDto, b: CompanyActivityDto) => 
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          
          setActivities(sortedActivities);
        } catch (error: any) {
          handleApiError(error, t);
        }
      };
      
      fetchCompanyActivity();
    }
  }, [companyId]);

  // Socket'ten yeni mazeret aktivitelerini dinle
  useEffect(() => {
    if (connected) {
      const handleNewActivity = (data: CompanyActivityDto) => {
        if (data.type === "excuse") {
          setActivities(prev => {
            // Aynı ID'li aktivite zaten varsa, ekleme
            if (prev.some(a => a.id === data.id)) {
              return prev;
            }
            
            // Yeni aktiviteyi ekle
            return [data, ...prev];
          });
        }
      };

      // Dinleyiciyi ekle
      on("newActivity", handleNewActivity);
    }
  }, [connected, on]);

  // Zaman formatını düzenle
  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="col-xxl-4 col-xl-6 d-flex">
      <div className="card flex-fill">
        <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
          <h5 className="mb-2">{t("dashboard.excuseStatus.title", "Gecikme Mazeretleri")}</h5>
        </div>
        <div className="card-body">
          <div style={{ maxHeight: '350px', overflowY: 'auto', marginBottom: '10px' }}>
            {activities.length > 0 ? (
              activities.map((activity) => (
                <div key={activity.id} className="d-flex align-items-center justify-content-between mb-3 p-2 border border-dashed br-5">
                  <div className="d-flex align-items-center">
                    <Link to={activity.user?.id ? routes.employeedetails.replace(':id', activity.user.id.toString()) : "#"} className="avatar flex-shrink-0">
                      {activity.user?.profilePicture ? (
                        <Avatar src={getFileUrl(activity.user.profilePicture)} size="large" />
                      ) : (
                        <Avatar icon={<UserOutlined />} size="default" />
                      )}
                    </Link>
                    <div className="ms-2">
                      <h6 className="fs-14 fw-medium text-truncate">
                        {activity.user?.firstName} {activity.user?.lastName}
                      </h6>
                      <p className="fs-13">
                        {activity.description}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="link-default me-2">
                      <i className="ti ti-alert-circle" />
                    </span>
                    <span className="fs-10 fw-medium d-inline-flex align-items-center badge badge-info">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      {formatTime(activity.createdAt)}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-3">
                <p className="mb-0">{t("dashboard.excuseStatus.noExcuses", "Bugün için mazeret bulunmuyor.")}</p>
              </div>
            )}
          </div>
          
          {/* <Link
            to="/attendance-report"
            className="btn btn-light btn-md w-100 mt-2"
          >
            {t("dashboard.excuseStatus.allRecords", "Tüm Kayıtlar")}
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default ExcuseStatus;
