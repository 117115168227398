import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import ilIlceData from '../../../../../core/data/json/il-ilce.json';

interface ContactInfoStepProps {
  form: FormInstance;
}

const { Option } = Select;

const ContactInfoStep: React.FC<ContactInfoStepProps> = ({ form }) => {
  const { t } = useTranslation();
  const [cities, setCities] = useState<{ il_adi: string; plaka_kodu: string }[]>([]);
  const [districts, setDistricts] = useState<{ ilce_adi: string; ilce_kodu: string }[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | undefined>(form.getFieldValue('city'));

  useEffect(() => {
    // İlleri yükle
    if (ilIlceData && ilIlceData.data) {
      const cityList = ilIlceData.data.map(city => ({
        il_adi: city.il_adi,
        plaka_kodu: parseInt(city.plaka_kodu.trim()).toString()
      }));
      setCities(cityList);
    }
    
    // Form değişikliklerini dinle
    const formCity = form.getFieldValue('city');
    if (formCity && formCity !== selectedCity) {
      setSelectedCity(formCity);
    }
  }, []);

  useEffect(() => {
    // Şehir değiştiğinde ilçeleri güncelle
    if (selectedCity) {
      const cityData = ilIlceData.data.find(city => city.il_adi === selectedCity);
      if (cityData && cityData.ilceler) {
        setDistricts(cityData.ilceler);
        
        // İlçe değerini koru, sadece şehir değiştiyse sıfırla
        const currentDistrict = form.getFieldValue('district');
        const isDistrictValid = cityData.ilceler.some(d => d.ilce_adi === currentDistrict);
        
        if (!isDistrictValid) {
          form.setFieldsValue({ district: undefined });
        }
      } else {
        setDistricts([]);
      }
    } else {
      setDistricts([]);
    }
  }, [selectedCity, form]);

  const handleCityChange = (value: string) => {
    setSelectedCity(value);
  };

  // Sadece rakamların girilebilmesi için regex (başında +90 olmadan)
  const turkishPhoneRegex = /^5[0-9]{2}[0-9]{3}[0-9]{2}[0-9]{2}$/;
  
  // Telefon numarası girişi için özel normalizer fonksiyonu
  const normalizePhoneNumber = (value: string) => {
    if (!value) return value;
    
    // Sadece rakamları tut
    const onlyNums = value.replace(/[^\d]/g, '');
    
    // Uzunluğu kontrol et ve maksimum 10 rakam al
    return onlyNums.slice(0, 10);
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.city')}</label>
          <Form.Item
            name="city"
            rules={[{ required: true, message: t('employees.validationErrors.city') }]}
          >
            <Select 
              placeholder={t('employees.modals.selectCity')} 
              onChange={handleCityChange}
              showSearch
              filterOption={(input, option) =>
                (option?.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map(city => (
                <Option key={city.plaka_kodu} value={city.il_adi}>
                  {city.il_adi}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.district')}</label>
          <Form.Item
            name="district"
            rules={[{ required: true, message: t('employees.validationErrors.district') }]}
          >
            <Select 
              placeholder={t('employees.modals.selectDistrict')}
              disabled={!selectedCity} 
              showSearch
              filterOption={(input, option) =>
                (option?.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {districts.map(district => (
                <Option key={district.ilce_kodu} value={district.ilce_adi}>
                  {district.ilce_adi}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      
      <div className="col-md-12">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.address')}</label>
          <Form.Item
            name="address"
            rules={[{ required: true, message: t('employees.validationErrors.address') }]}
          >
            <Input.TextArea rows={3} placeholder={t('employees.modals.enterAddress')} />
          </Form.Item>
        </div>
      </div>
      
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.email')}</label>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('employees.validationErrors.email') },
              { type: 'email', message: t('employees.validationErrors.emailFormat') }
            ]}
          >
            <Input placeholder="example@company.com" />
          </Form.Item>
        </div>
      </div>
      
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.phone')}</label>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: t('employees.validationErrors.phone') },
              { 
                pattern: turkishPhoneRegex, 
                message: t('employees.validationErrors.phoneFormat', { format: '5XX XXX XX XX' }) 
              }
            ]}
            getValueFromEvent={(e) => {
              const { value } = e.target;
              return value ? `+90${normalizePhoneNumber(value)}` : '';
            }}
            getValueProps={(value) => {
              // Form'da +90 ile başlayan değeri görüntülerken sadece rakam kısmını göster
              return {
                value: value ? value.replace('+90', '') : '',
              };
            }}
          >
            <Input 
              addonBefore="+90"
              placeholder="5XX XXX XX XX"
              maxLength={10}
            />
          </Form.Item>
        </div>
      </div>
      
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.emergencyPhone')}</label>
          <Form.Item
            name="emergencyContact"
            rules={[
              { required: true, message: t('employees.validationErrors.emergencyPhone') },
              { 
                pattern: turkishPhoneRegex, 
                message: t('employees.validationErrors.phoneFormat', { format: '5XX XXX XX XX' }) 
              }
            ]}
            getValueFromEvent={(e) => {
              const { value } = e.target;
              return value ? `+90${normalizePhoneNumber(value)}` : '';
            }}
            getValueProps={(value) => {
              // Form'da +90 ile başlayan değeri görüntülerken sadece rakam kısmını göster
              return {
                value: value ? value.replace('+90', '') : '',
              };
            }}
          >
            <Input 
              addonBefore="+90"
              placeholder="5XX XXX XX XX"
              maxLength={10}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoStep; 