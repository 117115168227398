import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { CompanyStatus } from '../../../../types/company.types';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend
} from 'recharts';

const { Title, Text } = Typography;

interface CompanyStatusData {
  activeCompanies: number;
  inactiveCompanies: number;
  suspendedCompanies: number;
  totalCompanies: number;
}

interface CompanyStatusChartProps {
  data: CompanyStatusData;
}

const CompanyStatusChart: React.FC<CompanyStatusChartProps> = ({ data }) => {
  // Pasta grafiği için veri hazırlığı
  const chartData = [
    { name: 'Aktif', value: data.activeCompanies, color: '#52c41a' },
    { name: 'Pasif', value: data.inactiveCompanies, color: '#8c8c8c' },
    { name: 'Askıya Alınmış', value: data.suspendedCompanies, color: '#ff4d4f' },
  ];

  // Boş durum kontrolü
  const isEmpty = data.totalCompanies === 0;

  return (
    <Card 
      title={<Title level={5}><BarChartOutlined /> Şirket Durumları</Title>} 
      className="dashboard-chart-card mb-4"
      bordered={false}
    >
      <div className="text-center mb-3">
        <Text type="secondary">Toplam {data.totalCompanies} şirket</Text>
      </div>

      {isEmpty ? (
        <div className="text-center py-5">
          <Text type="secondary">Henüz şirket bulunmuyor</Text>
        </div>
      ) : (
        <>
          <div className="chart-container" style={{ height: '250px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  dataKey="value"
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={90}
                  paddingAngle={5}
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => [`${value} şirket`, '']} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div className="chart-legend mt-4">
            <Row className="justify-content-center">
              {chartData.map((entry, index) => (
                <Col key={index} className="mx-3">
                  <div className="d-flex align-items-center">
                    <div 
                      style={{ 
                        backgroundColor: entry.color, 
                        width: 12, 
                        height: 12, 
                        borderRadius: '50%',
                        marginRight: 8
                      }}
                    />
                    <span>
                      <Text strong>{entry.name}</Text>: {entry.value} 
                      {data.totalCompanies > 0 && 
                        <Text type="secondary"> ({((entry.value / data.totalCompanies) * 100).toFixed(1)}%)</Text>
                      }
                    </span>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </Card>
  );
};

export default CompanyStatusChart; 