import React from 'react';
import { Row, Col, Card, Statistic, Typography, Progress, Tag, Tooltip } from 'antd';
import {
  BuildOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  HomeOutlined,
  ApartmentOutlined,
  ArrowUpOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../router/all_routes';
const { Text } = Typography;

interface StatsData {
  totalCompanies: number;
  activeCompanies: number;
  totalEmployees: number;
  totalBranches: number;
  totalDepartments: number;
}

interface StatCardsProps {
  stats: StatsData;
}

const StatCards: React.FC<StatCardsProps> = ({ stats }) => {
  // Şirket başına ortalama çalışan sayısını hesapla
  const avgEmployeesPerCompany = stats.totalCompanies > 0 
    ? (stats.totalEmployees / stats.totalCompanies).toFixed(1) 
    : '0';

  // Aktif şirket yüzdesini hesapla
  const activeCompanyPercent = stats.totalCompanies > 0 
    ? Math.round((stats.activeCompanies / stats.totalCompanies) * 100) 
    : 0;

  return (
    <Row gutter={[16, 16]} className="mb-4">
      <Col xs={24} sm={12} md={6}>
        <Card className="stat-card" bordered={false}>
          <Statistic
            title={<Text strong>Toplam Şirket</Text>}
            value={stats.totalCompanies}
            valueStyle={{ color: '#1890ff' }}
            prefix={<BuildOutlined className="stats-icon bg-primary-light text-primary" />}
            suffix={
              <Tooltip title="Şirket başına ortalama çalışan sayısı">
                <Tag color="blue">
                  {avgEmployeesPerCompany} çalışan/şirket
                </Tag>
              </Tooltip>
            }
          />
          <div className="stat-footer">
            <Progress 
              percent={100} 
              showInfo={false} 
              strokeColor="#1890ff" 
              size="small" 
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <Link to={all_routes.superAdminCompanies}>Tüm Şirketler</Link>
              <ArrowUpOutlined style={{ color: '#52c41a' }} />
            </div>
          </div>
        </Card>
      </Col>

      <Col xs={24} sm={12} md={6}>
        <Card className="stat-card" bordered={false}>
          <Statistic
            title={<Text strong>Aktif Şirketler</Text>}
            value={stats.activeCompanies}
            valueStyle={{ color: '#52c41a' }}
            prefix={<CheckCircleOutlined className="stats-icon bg-success-light text-success" />}
            suffix={
              <Tooltip title="Aktif şirket oranı">
                <Tag color="success">
                  {activeCompanyPercent}%
                </Tag>
              </Tooltip>
            }
          />
          <div className="stat-footer">
            <Progress 
              percent={activeCompanyPercent} 
              showInfo={false} 
              strokeColor="#52c41a"
              size="small"
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <Text type="secondary">Aktif Durum</Text>
              <ArrowUpOutlined style={{ color: '#52c41a' }} />
            </div>
          </div>
        </Card>
      </Col>

      <Col xs={24} sm={12} md={6}>
        <Card className="stat-card" bordered={false}>
          <Statistic
            title={<Text strong>Toplam Çalışan</Text>}
            value={stats.totalEmployees}
            valueStyle={{ color: '#722ed1' }}
            prefix={<TeamOutlined className="stats-icon bg-purple-light text-purple" />}
          />
          <div className="stat-footer">
            <Progress 
              percent={100} 
              showInfo={false} 
              strokeColor="#722ed1" 
              size="small" 
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <Text type="secondary">Tüm Şirketlerde</Text>
              <Tooltip title="Tüm şirketlerdeki çalışan sayısı">
                <Tag color="purple">Toplam Personel</Tag>
              </Tooltip>
            </div>
          </div>
        </Card>
      </Col>

      <Col xs={24} sm={12} md={6}>
        <Card className="stat-card" bordered={false}>
          <Statistic
            title={<Text strong>Toplam Birim</Text>}
            value={stats.totalBranches + stats.totalDepartments}
            valueStyle={{ color: '#faad14' }}
            prefix={<ApartmentOutlined className="stats-icon bg-warning-light text-warning" />}
            suffix={
              <Tooltip title="Şube ve departman sayıları">
                <div>
                  <Tag color="orange">{stats.totalBranches} Şube</Tag>
                  <Tag color="cyan">{stats.totalDepartments} Departman</Tag>
                </div>
              </Tooltip>
            }
          />
          <div className="stat-footer">
            <Progress 
              percent={100} 
              showInfo={false} 
              strokeColor="#faad14" 
              size="small" 
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <Text type="secondary">Toplam Birimler</Text>
              <ArrowUpOutlined style={{ color: '#faad14' }} />
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default StatCards; 