import React from 'react';
import { Form, Input, DatePicker, Select } from 'antd';
import form, { FormInstance } from 'antd/lib/form';
import Avatar from '../../../../shared/components/avatar/Avatar';
import { Link } from 'react-router-dom';
import CommonSelect from '../../../../../core/common/commonSelect';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
interface PersonalInfoStepProps {
  form: FormInstance;
}


const PersonalInfoStep: React.FC<PersonalInfoStepProps> = ({ form }) => {
  const { t } = useTranslation();

  // TC Kimlik No validasyonu
  const validateTCKN = (_: any, value: string) => {
    // if (!value) {
    //   return Promise.reject(t('employees.validationErrors.nationalId'));
    // }

    // Sadece rakamlardan oluşmalı
    if (!/^\d+$/.test(value)) {
      return Promise.reject(t('employees.validationErrors.nationalIdFormat'));
    }

    // 11 haneli olmalı
    if (value.length !== 11) {
      return Promise.reject(t('employees.validationErrors.nationalIdLength'));
    }

    // İlk rakam 0 olamaz
    if (value[0] === '0') {
      return Promise.reject(t('employees.validationErrors.nationalIdFirstDigit'));
    }

    // TCKN algoritması kontrolü
    // 1, 3, 5, 7, 9. basamakların toplamının 7 katından, 2, 4, 6, 8. basamakların toplamı çıkartıldığında
    // elde edilen sonucun 10'a bölümünden kalan 10. basamak değerini vermelidir
    const digits = value.split('').map(Number);
    
    // İlk 10 rakam için kontrol
    const oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
    const evenSum = digits[1] + digits[3] + digits[5] + digits[7];
    
    const tenthDigitCheck = (oddSum * 7 - evenSum) % 10 === digits[9];
    
    // Son rakam için kontrol (ilk 10 rakamın toplamının mod 10'u)
    const eleventhDigitCheck = (digits.slice(0, 10).reduce((sum, digit) => sum + digit, 0)) % 10 === digits[10];
    
    if (!tenthDigitCheck || !eleventhDigitCheck) {
      return Promise.reject(t('employees.validationErrors.nationalIdInvalid'));
    }

    return Promise.resolve();
  };

// const renderPersonalInfoTab = () => {
  return (
    <div className="row">
      {/* Profil Resmi */}
      {/* <div className="col-md-12">
        <div className="d-flex align-items-center flex-wrap row-gap-3 bg-light w-100 rounded p-3 mb-4">
          <div className="d-flex align-items-center justify-content-center avatar avatar-xxl rounded-circle border border-dashed me-2 flex-shrink-0 text-dark frames">
            <Form.Item
              name="profileImage"
              //rules={[{ required: true, message: 'Lütfen profil resmini seçiniz' }]}
            >
              <Avatar
                src="assets/img/users/user-13.jpg"
                alt="img"
                className="rounded-circle"
              />
            </Form.Item>
          </div>
          <div className="profile-upload">
            <div className="mb-2">
              <h6 className="mb-1">{t('employees.modals.profileImage')}</h6>
              <p className="fs-12">{t('employees.modals.profileImageDescription')}</p>
            </div>
            <div className="profile-uploader d-flex align-items-center">
              <div className="drag-upload-btn btn btn-sm btn-primary me-2"> 
                {t('employees.modals.upload')}
                <input
                  type="file"
                  className="form-control image-sign"
                  multiple
                />
              </div>
              <Link
                to="#"
                className="btn btn-light btn-sm"
              >
                {t('employees.modals.cancel')}
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      {/* Diğer Alanlar */}
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.name')}</label>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: t('employees.validationErrors.firstName') }]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.surname')}</label>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: t('employees.validationErrors.lastName') }]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.birthDate')}</label>
          <Form.Item
            name="birthDate"
            rules={[{ required: true, message: t('employees.validationErrors.birthDate') }]}
            getValueProps={(i) => ({
              value: i ? dayjs(i) : null
            })}
            getValueFromEvent={(date) => date ? date.format('YYYY-MM-DD') : null}
          >
            <DatePicker 
              className="form-control"
              format="YYYY-MM-DD"
              placeholder={t('employees.modals.selectBirthDate')}
            />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.nationalId')}</label>
          <Form.Item
            name="nationalId"
            rules={[
              { required: true, message: t('employees.validationErrors.nationalId') },
              { validator: validateTCKN }
            ]}
          >
            <Input 
              placeholder="TC Kimlik Numarası" 
              maxLength={11}
            />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
            <label className="form-label">{t('employees.modals.gender')}</label>
          <Form.Item
            name="gender"
            rules={[{ required: true, message: t('employees.validationErrors.gender') }]}
          >
            <Select
              placeholder={t('employees.modals.gender')}
              options={[
                { value: 'male', label: t('employees.modals.male') },
                { value: 'female', label: t('employees.modals.female') },
                { value: 'other', label: t('employees.modals.other') }
              ]}
            />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.maritalStatus')}</label>
          <Form.Item
            name="maritalStatus"
            rules={[{ required: true, message: t('employees.validationErrors.maritalStatus') }]}
          >
            <Select
              placeholder={t('employees.modals.maritalStatus')}
              options={[
                { value: 'single', label: t('employees.modals.single') },
                { value: 'married', label: t('employees.modals.married') }
              ]}
            />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.educationLevel')}</label>
          <Form.Item
            name="educationLevel"
            rules={[{ required: true, message: t('employees.validationErrors.educationLevel') }]}
          >
            <Select
            placeholder={t('employees.modals.educationLevel')}
            options={[
              { value: 'primary', label: t('employees.modals.primary') },
              { value: 'secondary', label: t('employees.modals.secondary') },
              { value: 'bachelor', label: t('employees.modals.bachelor') },
              { value: 'master', label: t('employees.modals.master') },
              { value: 'phd', label: t('employees.modals.phd') }
            ]}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoStep; 