import React from 'react';
import { Card, Typography, Table, Tag, Avatar, Empty, Tooltip } from 'antd';
import { 
  BuildOutlined, 
  CheckCircleOutlined, 
  CloseCircleOutlined, 
  PauseCircleOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
  HomeOutlined,
  PhoneOutlined,
  MailOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../router/all_routes';
import moment from 'moment';
import 'moment/locale/tr';

const { Title, Text } = Typography;

// Company statüs için enum
export enum CompanyStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended'
}

// Tablo için şirket verisi
interface CompanyData {
  id: number;
  name: string;
  email: string;
  phone: string;
  taxNumber: string;
  status: CompanyStatus;
  users?: { id: number }[];
  branches?: { id: number }[];
  departments?: { id: number }[];
  createdAt: string;
}

interface RecentCompaniesProps {
  companies: CompanyData[];
  loading?: boolean;
}

const RecentCompanies: React.FC<RecentCompaniesProps> = ({ companies, loading = false }) => {
  moment.locale('tr');

  // Şirket adından renk oluşturma fonksiyonu
  const colorFromString = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    const c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  };

  // Tablo sütunlarını tanımla
  const columns = [
    {
      title: 'Şirket',
      key: 'company',
      render: (text: string, record: CompanyData) => (
        <div className="d-flex align-items-center">
          <Avatar 
            style={{ backgroundColor: colorFromString(record.name), marginRight: '10px' }}
            icon={<BuildOutlined />}
          />
          <div>
            <Link to={all_routes.superAdminCompanyDetails.replace(':id', record.id.toString())} className="fw-bold">
              {record.name}
            </Link>
            <div className="text-muted small">{record.taxNumber}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'İletişim',
      key: 'contact',
      render: (text: string, record: CompanyData) => (
        <div>
          {record.email && <div><MailOutlined className="me-1" /> {record.email}</div>}
          {record.phone && <div><PhoneOutlined className="me-1" /> {record.phone}</div>}
        </div>
      ),
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (status: CompanyStatus) => {
        const statusInfo = {
          [CompanyStatus.ACTIVE]: { icon: <CheckCircleOutlined />, text: 'Aktif', color: 'success' },
          [CompanyStatus.INACTIVE]: { icon: <CloseCircleOutlined />, text: 'Pasif', color: 'default' },
          [CompanyStatus.SUSPENDED]: { icon: <PauseCircleOutlined />, text: 'Askıya Alınmış', color: 'error' },
        };

        const info = statusInfo[status] || { icon: <ExclamationCircleOutlined />, text: 'Bilinmiyor', color: 'processing' };
        
        return (
          <Tag icon={info.icon} color={info.color as any}>
            {info.text}
          </Tag>
        );
      },
    },
    {
      title: 'Çalışan/Şube',
      key: 'stats',
      render: (text: string, record: CompanyData) => (
        <div>
          <div><TeamOutlined className="me-1" /> {record.users?.length || 0} Çalışan</div>
          <div><HomeOutlined className="me-1" /> {record.branches?.length || 0} Şube</div>
        </div>
      ),
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => (
        <div>
          <CalendarOutlined className="me-1" /> 
          {moment(date).format('DD.MM.YYYY')}
          <div className="text-muted small">{moment(date).fromNow()}</div>
        </div>
      ),
    },
  ];

  return (
    <Card 
      title={<Title level={5}>Son Eklenen Şirketler</Title>} 
      extra={<Link to={all_routes.superAdminCompanies}>Tümünü Gör</Link>}
      className="recent-companies-card mb-4"
      bordered={false}
    >
      {companies.length > 0 ? (
        <Table 
          dataSource={companies} 
          columns={columns} 
          rowKey="id" 
          pagination={false}
          loading={loading}
          className="modern-table"
          rowClassName="table-row-hover"
        />
      ) : (
        <Empty description="Henüz şirket bulunmamaktadır" />
      )}
    </Card>
  );
};

export default RecentCompanies; 