import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Select,
  Divider,
  Switch,
  message,
  Alert,
  Space,
  TimePicker,
  Checkbox,
  Badge,
  Tooltip,
} from "antd";
import { Company, CompanyStatus } from "../../../types/company.types";
import companyService from "../../../services/company/company.service";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import {
  BuildOutlined,
  SaveOutlined,
  CloseOutlined,
  PhoneOutlined,
  MailOutlined,
  BankOutlined,
  NumberOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  UserOutlined,
  CalendarOutlined,
  GlobalOutlined,
  ApartmentOutlined,
  TeamOutlined,
  BellOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import "./styles.css";
import moment from "moment";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";
const { Option } = Select;
const { TextArea } = Input;

const CreateCompany = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFixedShiftSettings, setShowFixedShiftSettings] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();
  
  // Vardiya moduna göre sabit vardiya ayarlarının görünürlüğünü kontrol et
  useEffect(() => {
    const shiftMode = form.getFieldValue(['settings', 'shiftMode']);
    setShowFixedShiftSettings(shiftMode === 'fixed' || shiftMode === 'both');
  }, [form]);

  // Şirket oluşturma işlemi
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      setErrorMessage(null);

      // Vardiya zamanlarını string formatına dönüştürme - düzeltildi
      const shiftMode = values.settings?.shiftMode;
      if ((shiftMode === 'fixed' || shiftMode === 'both') && values.settings?.fixedShiftStartTime) {
        // Sadece string değeri almak için format kullanılıyor
        values.settings.fixedShiftStartTime = values.settings.fixedShiftStartTime.format("HH:mm");
      }
      
      if ((shiftMode === 'fixed' || shiftMode === 'both') && values.settings?.fixedShiftEndTime) {
        // Sadece string değeri almak için format kullanılıyor
        values.settings.fixedShiftEndTime = values.settings.fixedShiftEndTime.format("HH:mm");
      }

      console.log(values);
      // Şirket oluşturma API çağrısı
      //await companyService.createCompany(values);
      message.success("Şirket başarıyla oluşturuldu!");
      navigate(all_routes.superAdminCompanies);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  // Varsayılan değerleri ayarlama
  const initialValues = {
    name: "",
    address: "",
    phone: "",
    email: "",
    taxNumber: "",
    taxOffice: "",
    status: CompanyStatus.ACTIVE,
    defaultBreakDuration: 60,
    settings: {
      lateTolerance: true,
      lateTolerationMinutes: 0,
      sendLeaveRequestEmails: true,
      ipControl: false,
      annualLeaveLimit: 20,
      shiftMode: "assignment",
      fixedShiftDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      fixedShiftStartTime: moment("09:00", "HH:mm"),
      fixedShiftEndTime: moment("18:00", "HH:mm"),
    }
  };

  // Hafta günleri
  const weekDays = [
    { label: "Pazartesi", value: "Monday" },
    { label: "Salı", value: "Tuesday" },
    { label: "Çarşamba", value: "Wednesday" },
    { label: "Perşembe", value: "Thursday" },
    { label: "Cuma", value: "Friday" },
    { label: "Cumartesi", value: "Saturday" },
    { label: "Pazar", value: "Sunday" },
  ];

  // Vardiya modu değiştiğinde sabit vardiya ayarlarının görünürlüğünü güncelle
  const handleShiftModeChange = (value: string) => {
    setShowFixedShiftSettings(value === 'fixed' || value === 'both');
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-4">
            <div className="my-auto mb-2">
              <h2 className="mb-1">
                <BuildOutlined className="text-primary" /> 
                Yeni Şirket Oluştur
              </h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminCompanies}>Şirketler</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Yeni Şirket
                  </li>
                </ol>
              </nav>
            </div>
            <div className="head-icons ms-2">
              <Button
                danger
                icon={<CloseOutlined />}
                onClick={() => navigate(all_routes.superAdminCompanies)}
                className="me-2"
              >
                İptal
              </Button>
            </div>
          </div>
          {/* /Breadcrumb */}

          {errorMessage && (
            <Alert
              message="Hata"
              description={errorMessage}
              type="error"
              showIcon
              closable
              className="mb-4"
              style={{ margin: '0 auto 20px' }}
            />
          )}

          <Card className="company-form-card">
            <Form
              form={form}
              layout="vertical"
              initialValues={initialValues}
              onFinish={handleSubmit}
              className="company-form"
            >
              {/* Temel Bilgiler */}
              <div className="form-section">
                <div className="section-header">
                  <h3 className="section-title">
                    <BuildOutlined className="text-primary" /> 
                    Temel Bilgiler
                  </h3>
                  <p className="text-muted">Şirket için temel bilgileri buradan girebilirsiniz. Şirket adı zorunlu alandır.</p>
                </div>

                <Form.Item
                  name="name"
                  label="Şirket Adı"
                  rules={[
                    { required: true, message: "Şirket adı gereklidir" },
                  ]}
                >
                  <Input 
                    prefix={<ApartmentOutlined />}
                    placeholder="Şirket adını girin" 
                    className="form-input" 
                    size="large"
                  />
                </Form.Item>
                
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item 
                      name="email" 
                      label="E-posta"
                      rules={[{ type: 'email', message: 'Geçerli bir e-posta adresi girin' }]}
                    >
                      <Input
                        prefix={<MailOutlined />}
                        placeholder="E-posta adresini girin"
                        className="form-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="phone" label="Telefon">
                      <Input
                        prefix={<PhoneOutlined />}
                        placeholder="Telefon numarasını girin"
                        className="form-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="status" label="Durum">
                  <Select className="form-select" size="large" style={{padding:'0px'}}>
                    <Option value={CompanyStatus.ACTIVE}>
                      <Badge status="success" text="Aktif" />
                    </Option>
                    <Option value={CompanyStatus.INACTIVE}>
                      <Badge status="error" text="Pasif" />
                    </Option>
                    <Option value={CompanyStatus.SUSPENDED}>
                      <Badge status="warning" text="Askıya Alınmış" />
                    </Option>
                  </Select>
                </Form.Item>
                
                <Form.Item name="address" label="Adres">
                  <TextArea
                    placeholder="Şirket adresini girin"
                    rows={3}
                    className="form-textarea"
                  />
                </Form.Item>
              </div>

              {/* Vergi Bilgileri */}
              <div className="form-section">
                <div className="section-header">
                  <h3 className="section-title">
                    <BankOutlined className="text-primary" /> 
                    Vergi Bilgileri
                  </h3>
                  <p className="text-muted">Şirketin vergi numarası ve vergi dairesi bilgilerini girin.</p>
                </div>
                
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="taxNumber"
                      label={
                        <span>
                          Vergi No
                          <Tooltip title="Şirketin vergi kimlik numarasını girin">
                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                          </Tooltip>
                        </span>
                      }
                      rules={[
                        { required: true, message: "Vergi no gereklidir" },
                      ]}
                    >
                      <Input
                        prefix={<NumberOutlined />}
                        placeholder="Vergi numarasını girin"
                        className="form-input"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="taxOffice"
                      label="Vergi Dairesi"
                      rules={[
                        { required: true, message: "Vergi dairesi gereklidir" },
                      ]}
                    >
                      <Input 
                        prefix={<BankOutlined />}
                        placeholder="Vergi dairesini girin" 
                        className="form-input" 
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Sistem Ayarları */}
              <div className="form-section">
                <div className="section-header">
                  <h3 className="section-title">
                    <SettingOutlined className="text-primary" /> 
                    Şirket Ayarları
                  </h3>
                  <p className="text-muted">Şirketin çalışma düzeni, izin ve vardiya ayarlarını yapılandırın.</p>
                </div>
                
                <Card className="setting-card">
                  <h4 className="setting-title">
                    <ClockCircleOutlined style={{ marginRight: 8 }} /> 
                    Çalışma Düzeni
                  </h4>
                  
                  <Row gutter={24}>
                    <Col xs={24} md={8}>
                      <Form.Item
                        name={["settings", "lateTolerance"]}
                        label="Geç Kalma Toleransı"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.settings?.lateTolerance !==
                      currentValues.settings?.lateTolerance
                    }
                  >
                    {({ getFieldValue }) => {
                      return getFieldValue(["settings", "lateTolerance"]) ? (
                        <Form.Item
                          name={["settings", "lateTolerationMinutes"]}
                          label="Geç Kalma Tolerans Süresi (Dakika)"
                        >
                          <Input 
                            type="number" 
                            min={0} 
                            className="form-input"
                            size="large"
                            prefix={<ClockCircleOutlined />} 
                          />
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                </Card>
                
                <Card className="setting-card">
                  <h4 className="setting-title">
                    <BellOutlined style={{ marginRight: 8 }} /> 
                    İzin ve Bildirim Ayarları
                  </h4>
                  
                  <Row gutter={24}>
                    <Col xs={24} md={8}>
                      <Form.Item
                        name={["settings", "sendLeaveRequestEmails"]}
                        label="İzin Talebi E-postaları"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} md={8}>
                      <Form.Item
                        name={["settings", "ipControl"]}
                        label={
                          <span>
                            IP Kontrolü
                            <Tooltip title="Kullanıcıların belirlenen IP'lerden giriş yapmalarını zorunlu tutar">
                              <InfoCircleOutlined style={{ marginLeft: 8 }} />
                            </Tooltip>
                          </span>
                        }
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} md={8}>
                      <Form.Item
                        name={["settings", "annualLeaveLimit"]}
                        label="Yıllık İzin Limiti (Gün)"
                      >
                        <Input 
                          type="number" 
                          min={0} 
                          className="form-input"
                          size="large"
                          prefix={<CalendarOutlined />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                
                <Card className="setting-card">
                  <h4 className="setting-title">
                    <TeamOutlined style={{ marginRight: 8 }} /> 
                    Vardiya Ayarları
                  </h4>
                  
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name={["settings", "shiftMode"]}
                        label="Vardiya Modu"
                      >
                        <Select 
                          className="form-select" 
                          size="large"
                          onChange={handleShiftModeChange}
                          style={{padding:'0px'}}
                        >
                          <Option value="assignment">Atama Bazlı</Option>
                          <Option value="fixed">Sabit</Option>
                          <Option value="both">Her İkisi</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="defaultBreakDuration"
                        label="Varsayılan Mola Süresi (Dakika)"
                      >
                        <Input 
                          type="number" 
                          min={0} 
                          className="form-input"
                          size="large"
                          prefix={<ClockCircleOutlined />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {showFixedShiftSettings && (
                    <div className="fixed-shift-settings">
                      <Form.Item
                        name={["settings", "fixedShiftDays"]}
                        label="Sabit Vardiya Günleri"
                      >
                        <Checkbox.Group 
                          options={weekDays} 
                          className="days-checkbox-group"
                        />
                      </Form.Item>
                      
                      <Row gutter={24}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            name={["settings", "fixedShiftStartTime"]}
                            label="Vardiya Başlangıç Saati"
                          >
                            <TimePicker
                              format="HH:mm"
                              placeholder="Başlangıç saati"
                              style={{ width: "100%" }}
                              className="form-time-picker"
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                        
                        <Col xs={24} md={12}>
                          <Form.Item
                            name={["settings", "fixedShiftEndTime"]}
                            label="Vardiya Bitiş Saati"
                          >
                            <TimePicker
                              format="HH:mm"
                              placeholder="Bitiş saati"
                              style={{ width: "100%" }}
                              className="form-time-picker"
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Card>
              </div>

              {/* Form İşlemleri */}
              <div className="form-actions">
                <Button
                  danger
                  className="me-3"
                  icon={<CloseOutlined />}
                  onClick={() => navigate(all_routes.superAdminCompanies)}
                  size="large"
                >
                  İptal
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  loading={loading}
                  className="submit-button"
                  size="large"
                >
                  Şirketi Oluştur
                </Button>
              </div>
            </Form>
          </Card>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CreateCompany;
