import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { useEmployee } from "../../../hooks/useEmployee";
import { 
  Tabs, message, Card, Form, Input, Select, Radio, Switch, Button, 
  Row, Col, Space, Typography, Badge, Tooltip, Alert, Checkbox, Tag, Divider,
  Steps, Progress
} from 'antd';
import { 
  BellOutlined, SendOutlined, QuestionCircleOutlined, 
  UserOutlined, TeamOutlined, HomeOutlined, MailOutlined,
  PhoneOutlined, MessageOutlined, InfoCircleOutlined,
  LaptopOutlined, SettingOutlined, ArrowUpOutlined,
  ArrowDownOutlined, MinusOutlined, CheckOutlined,
  RightOutlined, LeftOutlined, WarningOutlined
} from '@ant-design/icons';
import './notification.css';
import { useTranslation } from 'react-i18next';
import { Rule } from 'antd/lib/form';
import notificationService from "../../../services/notification/notification.service";
import { handleApiError } from '../../../utils/errorHandler';
import FooterSection from "../../shared/components/footerSection";
const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
const { Step } = Steps;

interface NotificationForm {
    title: string;
    content: string;
    priority: 'low' | 'medium' | 'high';
    types: {
        notification: boolean;
        email: boolean;
        sms: boolean;
        //whatsapp: boolean;
    };
    targets: {
        type: 'department' | 'branch' | 'user' | 'all';
        ids: string[];
    }[];
}

const Notification = () => {
    const { t } = useTranslation();
    const { departments, shops, users, fetchUsers, fetchDepartmentsAndShops } = useEmployee();
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState<NotificationForm>({
        title: '',
        content: '',
        priority: 'medium',
        types: {
            notification: true,
            email: false,
            sms: false,
            //whatsapp: false
        },
        targets: [{
            type: 'all',
            ids: []
        }],
    });

    const steps = [
        {
            key: 'methods',
            title: t('notification.sendType'),
            icon: <SendOutlined />
        },
        {
            key: 'message',
            title: t('notification.messageTab'),
            icon: <MessageOutlined />
        },
        {
            key: 'recipients',
            title: t('notification.recipientsTab'),
            icon: <TeamOutlined />
        },
        {
            key: 'preview',
            title: t('notification.previewTab'),
            icon: <CheckOutlined />
        }
    ];

    useEffect(() => {
        fetchDepartmentsAndShops();
        fetchUsers();
    }, [fetchDepartmentsAndShops, fetchUsers]);

    useEffect(() => {
        // Önizleme adımına geçildiğinde, form değerlerini güncelle
        if (currentStep === 3) {
            const formValues = form.getFieldsValue(true);
            console.log("Adım 3'te form değerleri:", formValues);
            
            // formData state'ini form değerleriyle senkronize et
            setFormData(prevData => ({
                ...prevData,
                ...formValues
            }));
        }
    }, [currentStep]);

    const handleSubmit = async (values: any) => {
      try {
        setIsSubmitting(true);
        console.log("values", values);
        console.log("formData", formData);
        
        // En az bir gönderim yöntemi seçilmiş olmalı
        if (!Object.values(formData.types).some(Boolean)) {
            message.error(t('notification.minSendType'));
            setIsSubmitting(false);
            return;
        }

        // if(formData.targets.length === 0) {
        //     message.error(t('notification.selectAtLeastOneDepartmentOrBranch'));
        //     setIsSubmitting(false);
        //     return;
        // }

        // Her seçilen gönderim yöntemi için ayrı endpoint'e istek gönder
        const types  = []
        if (formData.types.notification) types.push("notification")
        if (formData.types.email) types.push("email")
        if (formData.types.sms) types.push("sms")
        //if (formData.types.whatsapp) types.push("whatsapp")
        const notificationData = {
            ...formData,
            types: types
        }
        
        const response = await notificationService.createNotification(notificationData);
        console.log("response", response);

        message.success(t('notification.notificationSuccess'));
        setIsSubmitting(false);
        // Gönderdikten sonra ilk adıma geri dön ve formu sıfırla
        setCurrentStep(0);
        form.resetFields();
        setFormData({
            title: '',
            content: '',
            priority: 'medium',
            types: {
                notification: true,
                email: false,
                sms: false,
                //whatsapp: false
            },
            targets: [{
                type: 'all',
                ids: []
            }],
        });
      } catch (error: any) {
        handleApiError(error, t);
        setIsSubmitting(false);
      }
    };

    const handleSendTypeChange = (type: 'notification' | 'email' | 'sms' 
        //| 'whatsapp'
    ) => {
        setFormData(prev => ({
            ...prev,
            types: {
                ...prev.types,
                [type]: !prev.types[type]
            }
        }));
    };

    const renderTooltip = (text: string) => (
        <Tooltip title={text}>
            <QuestionCircleOutlined className="info-icon" />
        </Tooltip>
    );

    const validateStepBeforeNext = async () => {
        try {
            if (currentStep === 0) {
                // Gönderim yöntemi validasyonu
                if (!Object.values(formData.types).some(Boolean)) {
                    message.error(t('notification.minSendType'));
                    return false;
                }
                return true;
            } else if (currentStep === 1) {
                // Mesaj bilgileri validasyonu
                const messageFields = ['title', 'content', 'priority'];
                await form.validateFields(messageFields);
                return true;
            } else if (currentStep === 2) {
                // Alıcılar validasyonu
                const targetType = form.getFieldValue('targetType');
                await form.validateFields(['targetType']);
                console.log("targetType", targetType);
                
                if (targetType === 'custom') {
                    if (!formData.targets.some(target => target.type === 'department' || target.type === 'branch')) {
                        message.error(t('notification.selectAtLeastOneDepartmentOrBranch'));
                        return false;
                    }
                } else if (targetType === 'specific') {
                    await form.validateFields(['selectedUsers']);
                }
                return true;
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    const nextStep = async () => {
        try {
            if (currentStep === 0) {
                // Mesaj içerik bilgilerini kontrol et
                await form.validateFields(['title', 'content', 'priority']);
                
                // Gönderim yöntemi kontrolü - en az bir tane seçilmiş olmalı
                if (!Object.values(formData.types).some(Boolean)) {
                    message.error(t('notification.selectAtLeastOneMethod'));
                    return;
                }
                
                setCurrentStep(1);
            } else if (currentStep === 1) {
                // Mesaj içerik bilgilerini kontrol et
                try {
                    await form.validateFields(['title', 'content', 'priority']);
                    
                    // Gönderim yöntemi kontrolü - en az bir tane seçilmiş olmalı
                    if (!Object.values(formData.types).some(Boolean)) {
                        message.error(t('notification.selectAtLeastOneMethod'));
                        return;
                    }
                    
                    setCurrentStep(2);
                } catch (error) {
                    console.error('Form validation error:', error);
                    return;
                }
            } else if (currentStep === 2) {
                // Gönderim zamanlaması seçimi                
                // Form verilerini alıp önizleme için kaydet
                const values = await form.validateFields();
                if(formData.targets.length === 0) {
                    if(values.targetType==='custom'){
                        message.error(t('notification.selectAtLeastOneDepartmentOrBranch'));
                        return;
                    }
                    if(values.targetType==='specific'){
                        message.error(t('notification.selectAtLeastOneUser'));
                        return;
                    }
                }
                else{
                    // formData içindeki değerleri güncelle
                    setFormData(prevData => ({
                        ...prevData,
                        title: values.title,
                        content: values.content,
                        priority: values.priority
                    }));
                    
                    setCurrentStep(3);
                }
                
            }
        } catch (error) {
            console.error('Form validation error:', error);
        }
    };

    const prevStep = () => {
        setCurrentStep(prev => prev - 1);
    };

    const renderSendMethodsContent = () => {
        const methods = [
            {
                key: 'notification',
                icon: <BellOutlined />,
                text: t('notification.notification'),
                color: '#1890ff'
            },
            {
                key: 'email',
                icon: <MailOutlined />,
                text: t('notification.email'),
                color: '#52c41a'
            },
            {
                key: 'sms',
                icon: <PhoneOutlined />,
                text: 'SMS',
                color: '#fa8c16'
            },
            // {
            //     key: 'whatsapp',
            //     icon: <MessageOutlined />,
            //     text: 'WhatsApp',
            //     color: '#13c2c2'
            // }
        ];

        return (
            <div className="send-methods">
                <Title level={5} className="send-methods-title">
                    <Space>
                        <SendOutlined />
                        {t('notification.sendType')}
                        {renderTooltip(t('notification.sendTypeHelp'))}
                    </Space>
                </Title>
                
                <Row gutter={[16, 16]}>
                    {methods.map(method => (
                        <Col xs={12} sm={6} key={method.key}>
                            <Card 
                                className={`send-method-card ${formData.types[method.key as keyof typeof formData.types] ? 'active' : ''}`}
                                onClick={() => handleSendTypeChange(method.key as any)}
                                hoverable
                            >
                                <div className="send-method-content">
                                    <div className="send-icon" style={{ backgroundColor: `${method.color}30` }}>
                                        <span style={{ color: method.color }}>{method.icon}</span>
                                    </div>
                                    <div className="send-text">
                                        <Text>{method.text}</Text>
                                    </div>
                                    <Checkbox 
                                        checked={formData.types[method.key as keyof typeof formData.types]} 
                                        onChange={() => handleSendTypeChange(method.key as any)}
                                    />
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    };

    const renderMessageContent = () => {
        return (
            <div className="message-form">
                <Form.Item
                    name="title"
                    label={
                        <Space>
                            {t('notification.title')}
                            {renderTooltip(t('notification.titleHelp'))}
                        </Space>
                    }
                    rules={[{ required: true, message: t('notification.titleRequired') }]}
                >
                    <Input 
                        placeholder={t('notification.titlePlaceholder')}
                        prefix={<InfoCircleOutlined style={{ color: '#1890ff' }} />}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    name="content"
                    label={
                        <Space>
                            {t('notification.content')}
                            {renderTooltip(t('notification.contentHelp'))}
                        </Space>
                    }
                    rules={[{ required: true, message: t('notification.contentRequired') }]}
                >
                    <TextArea 
                        placeholder={t('notification.contentPlaceholder')}
                        rows={6}
                    />
                </Form.Item>

                <Form.Item
                    name="priority"
                    label={
                        <Space>
                            {t('notification.priorityLevel')}
                            {renderTooltip(t('notification.priorityHelp'))}
                        </Space>
                    }
                    rules={[{ required: true, message: t('notification.priorityRequired') }]}
                >
                    <Radio.Group className="priority-radio-group">
                        <Row gutter={[16, 16]} className="priority-options">
                            <Col xs={24} sm={8}>
                                <Radio value="low">
                                    <div className="priority-option low">
                                        <div className="priority-badge">
                                            <ArrowDownOutlined />
                                        </div>
                                        <div className="priority-info">
                                            <Text strong>{t('notification.low')}</Text>
                                            <Progress percent={33} size="small" strokeColor="#52c41a" showInfo={false} />
                                            <Text type="secondary" className="priority-desc">{t('notification.lowDescription')}</Text>
                                        </div>
                                    </div>
                                </Radio>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Radio value="medium">
                                    <div className="priority-option medium">
                                        <div className="priority-badge">
                                            <MinusOutlined />
                                        </div>
                                        <div className="priority-info">
                                            <Text strong>{t('notification.medium')}</Text>
                                            <Progress percent={66} size="small" strokeColor="#faad14" showInfo={false} />
                                            <Text type="secondary" className="priority-desc">{t('notification.mediumDescription')}</Text>
                                        </div>
                                    </div>
                                </Radio>
                            </Col>
                            <Col xs={24} sm={8}>
                                <Radio value="high">
                                    <div className="priority-option high">
                                        <div className="priority-badge">
                                            <ArrowUpOutlined />
                                        </div>
                                        <div className="priority-info">
                                            <Text strong>{t('notification.high')}</Text>
                                            <Progress percent={100} size="small" strokeColor="#f5222d" showInfo={false} />
                                            <Text type="secondary" className="priority-desc">{t('notification.highDescription')}</Text>
                                        </div>
                                    </div>
                                </Radio>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>
            </div>
        );
    };

    const renderRecipientsContent = () => {
        return (
            <div className="recipients-form">
                <Form.Item
                    name="targetType"
                    label={
                        <Space>
                            {t('notification.recipients')}
                            {renderTooltip(t('notification.recipientsHelp'))}
                        </Space>
                    }
                    rules={[{ required: true, message: t('notification.recipientsRequired') }]}
                >
                    <Radio.Group className="target-radio-group" onChange={(e) => {
                        // targetType değiştiğinde targets dizisini sıfırla
                        const targetType = e.target.value;
                        
                        if (targetType === 'all') {
                            setFormData(prev => ({
                                ...prev,
                                targets: [{ type: 'all', ids: [] }]
                            }));
                        } else if (targetType === 'custom') {
                            setFormData(prev => ({
                                ...prev,
                                targets: []
                            }));
                        } else if (targetType === 'specific') {
                            setFormData(prev => ({
                                ...prev,
                                targets: []
                            }));
                        }
                    }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={8}>
                                <Radio.Button value="all" className="target-radio">
                                    <Card className="target-card">
                                        <div className="target-icon-wrapper">
                                            <TeamOutlined className="target-icon" />
                                        </div>
                                        <div className="target-info">
                                            <Title level={5}>{t('notification.allEmployees')}</Title>
                                            <Text type="secondary">{t('notification.allEmployeesDescription')}</Text>
                                        </div>
                                    </Card>
                                </Radio.Button>
                            </Col>
                            <Col xs={24} md={8}>
                                <Radio.Button value="custom" className="target-radio">
                                    <Card className="target-card">
                                        <div className="target-icon-wrapper">
                                            <HomeOutlined className="target-icon" />
                                        </div>
                                        <div className="target-info">
                                            <Title level={5}>{t('notification.departmentsAndBranches')}</Title>
                                            <Text type="secondary">{t('notification.departmentsAndBranchesDescription')}</Text>
                                        </div>
                                    </Card>
                                </Radio.Button>
                            </Col>
                            <Col xs={24} md={8}>
                                <Radio.Button value="specific" className="target-radio">
                                    <Card className="target-card">
                                        <div className="target-icon-wrapper">
                                            <UserOutlined className="target-icon" />
                                        </div>
                                        <div className="target-info">
                                            <Title level={5}>{t('notification.customSelection')}</Title>
                                            <Text type="secondary">{t('notification.customSelectionDescription')}</Text>
                                        </div>
                                    </Card>
                                </Radio.Button>
                            </Col>
                        </Row>
                    </Radio.Group>
                </Form.Item>

                <Form.Item 
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.targetType !== currentValues.targetType}
                >
                    {({ getFieldValue }) => {
                        const targetType = getFieldValue('targetType');
                        
                        if (targetType === 'custom') {
                            return (
                                <div className="departments-branches-selection">
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} md={12}>
                                            <Card className="selection-card" title={
                                                <Space>
                                                    <LaptopOutlined />
                                                    {t('notification.departmentSelection')}
                                                    <Switch 
                                                        checkedChildren={t('notification.all')} 
                                                        unCheckedChildren={t('notification.select')}
                                                        checked={formData.targets.some(target => 
                                                            target.type === 'department' && 
                                                            target.ids.length === departments.length
                                                        )}
                                                        onChange={(checked) => {
                                                            if (checked) {
                                                                // Tüm departmanlar seçildi - tüm departman ID'lerini ekle
                                                                const allDepartmentIds = departments.map(dept => dept.id.toString());
                                                                const departmentTarget = { type: 'department' as const, ids: allDepartmentIds };
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: [...prev.targets.filter(t => t.type !== 'department'), departmentTarget]
                                                                }));
                                                            } else {
                                                                // Departmanlar seçimi kaldırıldı
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: prev.targets.filter(t => t.type !== 'department')
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </Space>
                                            }>
                                                {!formData.targets.some(target => target.type === 'department' && target.ids.length === 0) && (
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={t('notification.selectDepartments')}
                                                        style={{ width: '100%' }}
                                                        value={formData.targets.find(t => t.type === 'department')?.ids || []}
                                                        tagRender={props => {
                                                            // ID'den departman adını bul
                                                            const departmentName = departments.find(
                                                                dept => dept.id.toString() === props.value
                                                            )?.name || props.value;
                                                            
                                                            return (
                                                                <Tag closable onClose={props.onClose} color="blue">
                                                                    {departmentName}
                                                                </Tag>
                                                            );
                                                        }}
                                                        onChange={(values) => {
                                                            // Seçilen departmanları targets dizisine ekle
                                                            if (values && values.length > 0) {
                                                                const departmentTarget = { type: 'department' as const, ids: values as string[] };
                                                                
                                                                // Tüm departmanlar seçildi mi kontrol et
                                                                const isAllSelected = values.length === departments.length;
                                                                
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: [...prev.targets.filter(t => t.type !== 'department'), departmentTarget]
                                                                }));
                                                            } else {
                                                                // Seçim boşsa targets dizisinden kaldır
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: prev.targets.filter(t => t.type !== 'department')
                                                                }));
                                                            }
                                                        }}
                                                    >
                                                        {departments.map(dept => (
                                                            <Option key={dept.id} value={dept.id.toString()}>
                                                                {dept.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Card>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Card className="selection-card" title={
                                                <Space>
                                                    <HomeOutlined />
                                                    {t('notification.branchSelection')}
                                                    <Switch 
                                                        checkedChildren={t('notification.all')} 
                                                        unCheckedChildren={t('notification.select')}
                                                        checked={formData.targets.some(target => 
                                                            target.type === 'branch' && 
                                                            target.ids.length === shops.length
                                                        )}
                                                        onChange={(checked) => {
                                                            if (checked) {
                                                                // Tüm şubeler seçildi - tüm şube ID'lerini ekle
                                                                const allBranchIds = shops.map(shop => shop.id.toString());
                                                                const branchTarget = { type: 'branch' as const, ids: allBranchIds };
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: [...prev.targets.filter(t => t.type !== 'branch'), branchTarget]
                                                                }));
                                                            } else {
                                                                // Şubeler seçimi kaldırıldı
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: prev.targets.filter(t => t.type !== 'branch')
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </Space>
                                            }>
                                                {!formData.targets.some(target => target.type === 'branch' && target.ids.length === 0) && (
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={t('notification.selectBranches')}
                                                        style={{ width: '100%' }}
                                                        value={formData.targets.find(t => t.type === 'branch')?.ids || []}
                                                        tagRender={props => {
                                                            // ID'den şube adını bul
                                                            const branchName = shops.find(
                                                                shop => shop.id.toString() === props.value
                                                            )?.name || props.value;
                                                            
                                                            return (
                                                                <Tag closable onClose={props.onClose} color="green">
                                                                    {branchName}
                                                                </Tag>
                                                            );
                                                        }}
                                                        onChange={(values) => {
                                                            // Seçilen şubeleri targets dizisine ekle
                                                            if (values && values.length > 0) {
                                                                const branchTarget = { type: 'branch' as const, ids: values as string[] };
                                                                
                                                                // Tüm şubeler seçildi mi kontrol et
                                                                const isAllSelected = values.length === shops.length;
                                                                
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: [...prev.targets.filter(t => t.type !== 'branch'), branchTarget]
                                                                }));
                                                            } else {
                                                                // Seçim boşsa targets dizisinden kaldır
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    targets: prev.targets.filter(t => t.type !== 'branch')
                                                                }));
                                                            }
                                                        }}
                                                    >
                                                        {shops.map(shop => (
                                                            <Option key={shop.id} value={shop.id.toString()}>
                                                                {shop.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        }
                        
                        if (targetType === 'specific') {
                            return (
                                <div className="users-selection">
                                    <Card className="selection-card" title={
                                        <Space>
                                            <UserOutlined />
                                            {t('notification.employeeSelection')}
                                        </Space>
                                    }>
                                        <Select
                                            mode="multiple"
                                            placeholder={t('notification.selectEmployees')}
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            showSearch
                                            value={formData.targets.find(t => t.type === 'user')?.ids || []}
                                            tagRender={props => (
                                                <Tag closable onClose={props.onClose} color="purple">
                                                    {props.label}
                                                </Tag>
                                            )}
                                            onChange={(values) => {
                                                // Seçilen kullanıcıları targets dizisine ekle
                                                if (values && values.length > 0) {
                                                    const userTarget = { type: 'user' as const, ids: values as string[] };
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        targets: [userTarget]
                                                    }));
                                                } else {
                                                    // Seçim boşsa targets dizisini boşalt
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        targets: []
                                                    }));
                                                }
                                            }}
                                        >
                                            {users?.map(user => (
                                                <Option key={user.id} value={user.id}>
                                                    {user.firstName} {user.lastName} - {user.email}
                                                </Option>
                                            ))}
                                        </Select>
                                        <Text type="secondary" className="selection-tip">
                                            <InfoCircleOutlined /> {t('notification.multipleSelection')}
                                        </Text>
                                    </Card>
                                </div>
                            );
                        }
                        
                        return null;
                    }}
                </Form.Item>
            </div>
        );
    };

    const renderPreview = () => {
        // Form değerlerini doğrudan form'dan al
        const values = form.getFieldsValue(true);
        
        // Hedef tipine göre doğru bilgileri almak için değişkenler tanımlayalım
        const selectedDepartmentIds = formData.targets.find(t => t.type === 'department')?.ids || [];
        const selectedBranchIds = formData.targets.find(t => t.type === 'branch')?.ids || [];
        const selectedUserIds = formData.targets.find(t => t.type === 'user')?.ids || [];
        
        // Departman, şube ve kullanıcı isimlerini ID'lerden elde edelim
        // toString kullanarak ID karşılaştırmalarında oluşabilecek tip sorunlarını önlüyoruz
        const selectedDepartmentNames = departments
            .filter(dept => selectedDepartmentIds.some(id => id.toString() === dept.id.toString()))
            .map(dept => dept.name);
            
        const selectedBranchNames = shops
            .filter(shop => selectedBranchIds.some(id => id.toString() === shop.id.toString()))
            .map(shop => shop.name);
            
        const selectedUserNames = users
            .filter(user => selectedUserIds.some(id => id.toString() === user.id.toString()))
            .map(user => `${user.firstName} ${user.lastName}`);
        
        return (
            <div className="preview-content">
                <Form.Item noStyle>
                    <div className="notification-preview">
                        <div className="preview-header">
                            <Space>
                                <CheckOutlined />
                                {t('notification.previewTitle')}
                            </Space>
                        </div>
                        
                        <div className="preview-body">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Card title={t('notification.messagePreview')} className="message-preview-card">
                                        <div className="preview-item">
                                            <Text strong>{t('notification.title')}:</Text>
                                            <Paragraph>{values.title || t('notification.noTitle')}</Paragraph>
                                        </div>
                                        <div className="preview-item">
                                            <Text strong>{t('notification.content')}:</Text>
                                            <Paragraph>{values.content || t('notification.noContent')}</Paragraph>
                                        </div>
                                        <div className="preview-item">
                                            <Text strong>{t('notification.priorityLevel')}:</Text>
                                            <div>
                                                {values.priority === 'low' && (
                                                    <Tag color="green">{t('notification.low')}</Tag>
                                                )}
                                                {values.priority === 'medium' && (
                                                    <Tag color="orange">{t('notification.medium')}</Tag>
                                                )}
                                                {values.priority === 'high' && (
                                                    <Tag color="red">{t('notification.high')}</Tag>
                                                )}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                
                                <Col xs={24} md={12}>
                                    <Card title={t('notification.deliveryPreview')} className="delivery-preview-card">
                                        <div className="preview-item">
                                            <Text strong>{t('notification.sendMethods')}:</Text>
                                            <div className="method-tags">
                                                {formData.types.notification && (
                                                    <Tag color="blue" icon={<BellOutlined />}>{t('notification.notification')}</Tag>
                                                )}
                                                {formData.types.email && (
                                                    <Tag color="green" icon={<MailOutlined />}>{t('notification.email')}</Tag>
                                                )}
                                                {formData.types.sms && (
                                                    <Tag color="orange" icon={<PhoneOutlined />}>SMS</Tag>
                                                )}
                                                {/* {formData.types.whatsapp && (
                                                    <Tag color="cyan" icon={<MessageOutlined />}>WhatsApp</Tag>
                                                )} */}
                                            </div>
                                        </div>
                                        
                                        <div className="preview-item">
                                            <Text strong>{t('notification.recipients')}:</Text>
                                            <div>
                                                {values.targetType === 'all' && (
                                                    <Tag color="blue" icon={<TeamOutlined />}>{t('notification.allEmployees')}</Tag>
                                                )}
                                                {values.targetType === 'custom' && (
                                                    <div className="custom-tags">
                                                        {/* Departmanlar için bilgi */}
                                                        {formData.targets.some(target => target.type === 'department') && (
                                                            <div className="mb-2">
                                                                {formData.targets.some(target => target.type === 'department' && target.ids.length === 0) ? (
                                                                    <Tag color="blue" icon={<LaptopOutlined />}>{t('notification.allDepartments')}</Tag>
                                                                ) : (
                                                                    <div className="mb-1">
                                                                        <Text strong>{t('notification.selectedDepartments')}:</Text>
                                                                        <div className="mt-1">
                                                                            {selectedDepartmentNames.length > 0 ? (
                                                                                selectedDepartmentNames.map(name => (
                                                                                    <Tag key={name} color="blue" icon={<LaptopOutlined />}>{name}</Tag>
                                                                                ))
                                                                            ) : (
                                                                                <Tag color="blue" icon={<LaptopOutlined />}>{t('notification.noDepartmentSelected')}</Tag>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                        
                                                        {/* Şubeler için bilgi */}
                                                        {formData.targets.some(target => target.type === 'branch') && (
                                                            <div className="mb-2">
                                                                {formData.targets.some(target => target.type === 'branch' && target.ids.length === 0) ? (
                                                                    <Tag color="green" icon={<HomeOutlined />}>{t('notification.allBranches')}</Tag>
                                                                ) : (
                                                                    <div>
                                                                        <Text strong>{t('notification.selectedBranches')}:</Text>
                                                                        <div className="mt-1">
                                                                            {selectedBranchNames.length > 0 ? (
                                                                                selectedBranchNames.map(name => (
                                                                                    <Tag key={name} color="green" icon={<HomeOutlined />}>{name}</Tag>
                                                                                ))
                                                                            ) : (
                                                                                <Tag color="green" icon={<HomeOutlined />}>{t('notification.noBranchSelected')}</Tag>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {values.targetType === 'specific' && (
                                                    <div>
                                                        <Text strong>{t('notification.selectedEmployees')}:</Text>
                                                        <div className="mt-1">
                                                            {selectedUserNames.length > 0 ? (
                                                                selectedUserNames.map(name => (
                                                                    <Tag key={name} color="purple" icon={<UserOutlined />}>{name}</Tag>
                                                                ))
                                                            ) : (
                                                                <Tag color="purple" icon={<UserOutlined />}>{t('notification.noEmployeeSelected')}</Tag>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <Alert
                            message={t('notification.sendConfirmation')}
                            description={t('notification.sendConfirmationDescription')}
                            type="info"
                            showIcon
                            icon={<WarningOutlined />}
                            className="mt-4"
                        />
                    </div>
                </Form.Item>
            </div>
        );
    };

    return (
        <>
            {/* Page Wrapper */}
            <div className="page-wrapper">
                <div className="content container-fluid">
                    {/* Breadcrumb */}
                    <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                        <div className="my-auto mb-2">
                            <h2 className="mb-1">{t('notification.pageTitle')}</h2>
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to={all_routes.adminDashboard}>
                                            <i className="ti ti-smart-home" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">{t('notification.breadcrumb.management')}</li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t('notification.breadcrumb.send')}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div>
                            <Link to={all_routes.notificationList} className="btn btn-primary me-2">
                                <i className="ti ti-list" /> {t('notification.listAll')}
                            </Link>
                        </div>
                    </div>
                    {/* /Breadcrumb */}

                    <Alert
                        message={t('notification.info')}
                        description={t('notification.infoDescription')}
                        type="info"
                        showIcon
                        closable
                        className="mb-4"
                    />

                    <Card className="notification-card">
                        <Steps current={currentStep} className="notification-steps mb-4">
                            {steps.map(item => (
                                <Step key={item.key} title={item.title} icon={item.icon} />
                            ))}
                        </Steps>

                        <Divider />

                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                                title: '',
                                content: '',
                                priority: 'medium',
                                targetType: 'all',
                            }}
                            onFinish={(values) => {
                                if (currentStep === 3) {
                                    handleSubmit(values);
                                }
                            }}
                        >
                            <div className="steps-content">
                                {currentStep === 0 && renderSendMethodsContent()}
                                {currentStep === 1 && renderMessageContent()}
                                {currentStep === 2 && renderRecipientsContent()}
                                {currentStep === 3 && renderPreview()}
                            </div>

                            <div className="steps-action mt-4">
                                {currentStep > 0 && (
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        onClick={() => setCurrentStep(currentStep - 1)}
                                        icon={<LeftOutlined />}
                                        htmlType="button"
                                    >
                                        {t('notification.previousStep')}
                                    </Button>
                                )}
                                {currentStep < steps.length - 1 && (
                                    <Button 
                                        type="primary" 
                                        onClick={nextStep}
                                        icon={<RightOutlined />}
                                        htmlType="button"
                                    >
                                        {t('notification.nextStep')}
                                    </Button>
                                )}
                                {currentStep === steps.length - 1 && (
                                    <Button
                                        type="primary"
                                        icon={<SendOutlined />}
                                        htmlType="submit"
                                        loading={isSubmitting}
                                    >
                                        {t('notification.send')}
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </Card>
                </div>
                <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                    <FooterSection />
                </div>
            </div>
            {/* /Page Wrapper */}
        </>
    );
};

export default Notification;