import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { 
  BuildOutlined, 
  PlusOutlined, 
  SettingOutlined, 
  DashboardOutlined,
  ApartmentOutlined,
  UserOutlined,
  FileTextOutlined,
  BankOutlined
} from '@ant-design/icons';
import { all_routes } from '../../../../router/all_routes';

const { Title } = Typography;

interface QuickAccessCardsProps {
  companyCount: number;
}

const QuickAccessCards: React.FC<QuickAccessCardsProps> = ({ companyCount }) => {
  // Hızlı erişim kartları için veriler
  const quickLinks = [
    {
      title: 'Şirketler',
      icon: <BuildOutlined className="quick-link-icon" />,
      count: companyCount,
      link: all_routes.superAdminCompanies
    },
    {
      title: 'Yeni Şirket',
      icon: <PlusOutlined className="quick-link-icon" />,
      hint: 'Şirket Ekle',
      link: all_routes.superAdminCompanyCreate
    },
    {
      title: 'Hakkımızda',
      icon: <FileTextOutlined className="quick-link-icon" />,
      hint: 'İçerik Düzenle',
      link: all_routes.superAdminAboutUs
    },
    {
      title: 'Sistem Ayarları',
      icon: <SettingOutlined className="quick-link-icon" />,
      hint: 'Ayarları Yönet',
      link: '#'
    }
  ];

  return (
    <Card 
      title={<Title level={5}>Hızlı Erişim</Title>} 
      className="quick-access-card mb-4"
      bordered={false}
    >
      <Row gutter={[16, 16]}>
        {quickLinks.map((link, index) => (
          <Col xs={24} sm={12} md={6} key={index}>
            <Link to={link.link} className="quick-link-card">
              {link.icon}
              <span className="quick-link-title">{link.title}</span>
              {link.count !== undefined ? (
                <div className="quick-link-count">{link.count}</div>
              ) : (
                <div className="quick-link-hint">{link.hint}</div>
              )}
            </Link>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default QuickAccessCards; 